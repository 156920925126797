@import '../../../../mixins';

.event-list {
	position: relative;
	margin-top: 20px;
	width: 100%;

	.event {
		position: relative;
		height: 100%;
		width: 100%;
		background-color: rgba(255, 255, 255, 0.1);
		padding: 8px 12px;
		display: inline-flex;
		flex-direction: row;
		align-items: center;
		border-radius: 6px;

		@media (hover) {
			&:hover {
				background-color: rgba(255, 255, 255, 0.3);
			}
		}

		.event-play-icon {
			display: none;
			height: 50px;
			width: 50px;
			min-width: 50px;
			min-height: 50px;
			margin-right: 12px;
			background-image: url('../../../../../public/images/play.png');
			background-size: 100% 100%;
		}

		.event-live-dot {
			display: none;
			position: absolute;
			top: 10px;
			right: 10px;
			width: 10px;
			height: 10px;
			-webkit-border-radius: 50%;
			-moz-border-radius: 50%;
			border-radius: 50%;
			background-color: #e60000;
		}

		&.playable {
			cursor: pointer;

			.event-play-icon {
				display: block;
			}
		}

		&.live .event-live-dot {
			display: block;
		}

		.event-info-wrapper {
			overflow: hidden;
		}

		.event-title {
			font-size: 20px;
			font-weight: bold;
			line-height: 1.2;
			@include noselect();
			@include ellipsis();
		}

		.event-subtitle {
			font-size: 16px;
			line-height: 1.2;
			@include noselect();
		}
	}

	.events-actions {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin-right: -50px;
		margin-left: -50px;
		pointer-events: none;
		opacity: 0;

		@include media-breakpoint-down(md) {
			margin-right: -30px;
			margin-left: -30px;
		}

		@include media-breakpoint-down(sm) {
			margin-right: -20px;
			margin-left: -20px;
		}

		.previous,
		.next {
			position: absolute;
			width: 40px;
			height: 100%;
			cursor: pointer;
			background-color: #333;
			background-repeat: no-repeat;
			background-position: center;
			background-size: 14px 28px;
			z-index: 1;
			opacity: 0;

			&.visible {
				opacity: 1;
				pointer-events: auto;
			}

			@media (hover) {
				@include transition(opacity 0.2s ease-in);
			}
		}

		.previous {
			background-image: url('../../../../../public/images/chevron-left.png');
			left: 0;
		}

		.next {
			background-image: url('../../../../../public/images/chevron-right.png');
			right: 0;
		}
	}

	@media (hover) {
		.events-actions {
			display: block;
			opacity: 0.5;
			@include transition(opacity 0.2s ease-in);
		}

		&:hover {
			.events-actions {
				opacity: 0.85;
			}
		}
	}
}
