@import '../../mixins';

.player-container {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: #000;
	z-index: 15;
	.chromecast-playback {
		position: absolute;
		background: url('../../../public/images/default-bg@1.5x.png') no-repeat;
		background-size: cover;
		background-color: #000;
		background-attachment: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		padding-left: 50px;
		padding-right: 50px;
		padding-top: 50px;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		display: none;

		.chromecast-info-wrapper {
			max-width: 80%;

			@include media-breakpoint-down(sm) {
				max-width: 90%;
			}
		}

		.chromecast-title-wrapper {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			height: 60px;

			.chromecast-icon {
				background-image: url('../../../public/images/cast_connected.png');
				background-size: 100% 100%;
				width: 60px;
				height: 60px;
				margin-right: 20px;
			}
			.chromecast-playback-title {
				font-size: 60px;
				font-weight: 300;
				line-height: 1.2;
				@include ellipsis();

				@include media-breakpoint-down(sm) {
					font-size: 48px;
				}
			}
		}

		.chromecast-playback-subtitle {
			font-size: 46px;
			color: #aaa;
			font-weight: 300;
			line-height: 1.2;
			@include ellipsis();

			@include media-breakpoint-down(sm) {
				font-size: 36px;
			}
		}

		&.visible {
			display: flex;
		}
	}
}

.theoplayer-texttracks {
	top: 0 !important;
	left: 0 !important;
	right: 0 !important;
	bottom: 50px !important;

	span,
	text {
		background: transparent !important;
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;
		font-size: 3rem;
		line-height: 3.5rem;
		font-family: Verdana, sans-serif;
		text-shadow: rgb(0, 0, 0) -2px -2px 0px, rgb(0, 0, 0) 2px -2px 0px, rgb(0, 0, 0) -2px 2px 0px, rgb(0, 0, 0) 2px 2px 0px !important;

		@include media-breakpoint-down(sm) {
			font-size: 1rem;
			line-height: 1.2rem;
		}
	}

	br {
		display: none;
	}
}

.player-container.playback-information-visible {
	.theoplayer-texttracks {
		bottom: 250px !important;

		@include media-breakpoint-down(sm) {
			bottom: 50px !important;
		}
	}
}
