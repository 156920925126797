@import '../../../../variables';
@import '../../../../mixins';

.player-state-icon {
	flex: 0 0 auto;
	cursor: pointer;
	width: 110px;
	height: 110px;
	background-size: 100% 100%;
	background-repeat: no-repeat;
	margin-right: 40px;

	@include media-breakpoint-down(md) {
		width: 100px;
		height: 100px;
		min-height: 100px;
		margin-right: 30px;
	}

	@include media-breakpoint-down(sm) {
		position: absolute;
		top: 50%;
		left: 50%;
		margin-top: -50px;
		margin-left: -50px;
	}

	&.buffering {
		width: 100px;
		min-width: 100px;
		height: 100px;
		min-height: 100px;
		margin: 5px 45px 8px 5px;
		background-image: url('../../../../../public/images/tail-spin.svg');

		@include media-breakpoint-down(md) {
			width: 90px;
			min-width: 90px;
			height: 90px;
			min-height: 90px;
			margin: 5px 35px 8px 5px;
		}

		@include media-breakpoint-down(sm) {
			margin-top: -45px;
			margin-left: -45px;
		}
	}

	&.playing {
		background-image: url('../../../../../public/images/player-controls-pause.png');
	}

	&.paused {
		background-image: url('../../../../../public/images/player-controls-play.png');
	}
}
