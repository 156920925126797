$progressIndicatorRadius: 10px;
$progressAnimationDuration: 0.1s;

.progress-bar-container {
	width: 100%;
	height: 4 * $progressIndicatorRadius;
	display: flex;
	align-items: center;
}

.progress-bar {
	width: 100%;
	height: 2 * $progressIndicatorRadius;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-left: 30px;
	margin-right: 40px;
	background-color: transparent;
	overflow: visible;

	.progress-elapsed {
		height: 3px;
		background-color: #e60000;
		border-radius: 6px;
	}

	.progress-indicator {
		background-color: #fff;
		width: 2 * $progressIndicatorRadius;
		height: 2 * $progressIndicatorRadius;
		min-width: 2 * $progressIndicatorRadius;
		min-height: 2 * $progressIndicatorRadius;
		border-radius: $progressIndicatorRadius;
		margin-left: -$progressIndicatorRadius;
		margin-right: -$progressIndicatorRadius;
	}

	.progress-remaining {
		height: 3px;
		background-color: #fff;
		border-radius: 6px;
	}
}
