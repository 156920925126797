@import "variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@mixin transition($transition...) {
    -moz-transition: $transition;
    -o-transition: $transition;
    -webkit-transition: $transition;
    transition: $transition;
}

@mixin transform($transformation...) {
    -moz-transform: $transformation;
    -o-transform: $transformation;
    -webkit-transform: $transformation;
    transform: $transformation;
}

@mixin highlight() {
    display: none;
    @media (hover) {
        opacity: 0;
        display: block;
        position: absolute;
        top: -6px;
        left: -6px;
        right: -6px;
        bottom: -6px;
        border: 4px solid $primary;
        @include transition(opacity 0.2s ease-in-out);

        &:hover {
            opacity: 1;
        }
    }
}

@mixin ellipsis() {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
}

@mixin noselect() {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

@mixin button-reset() {
    margin: 0;
    padding: 0;
    border: none;
    background: none;
    outline: none;
}