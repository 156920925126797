@import '../../variables';
@import '../../mixins';

.content-info {
	display: flex;
	flex: 1;
	flex-direction: column;
	max-width: 100%;

	.age-rating {
		float: left;
		width: 35px;
		height: 35px;
		margin-top: 10px;
		margin-left: 30px;
		background-size: 35px 35px;
		background-repeat: no-repeat;
	}

	.age-rating-0 {
		background-image: url('../../../public/images/0@1.5x.png');
	}

	.age-rating-6 {
		background-image: url('../../../public/images/6@1.5x.png');
	}

	.age-rating-9 {
		background-image: url('../../../public/images/9@1.5x.png');
	}

	.age-rating-12 {
		background-image: url('../../../public/images/12@1.5x.png');
	}

	.age-rating-12 {
		background-image: url('../../../public/images/12@1.5x.png');
	}

	.age-rating-16 {
		background-image: url('../../../public/images/16@1.5x.png');
	}

	.age-rating-18 {
		background-image: url('../../../public/images/18@1.5x.png');
	}

	.details {
		font-size: 20px;
		font-weight: bold;
		line-height: 1.2;
		margin-bottom: 20px;

		> div {
			margin-bottom: 10px;
		}

		.title {
			margin-right: 10px;
		}

		.data {
			color: #a197a1;
		}
	}

	.subtitle,
	.details-container {
		overflow: hidden;

		> div {
			margin-bottom: 10px;

			&:last-child {
				margin-bottom: 0;
			}

			@include media-breakpoint-up(sm) {
				float: left;
				margin-right: 20px;
				margin-bottom: 0;
				padding-right: 20px;

				border-right: 1px solid #666;

				&:last-child {
					border-right: none;
				}
			}
		}
	}

	.not-available-on-device {
		font-size: 20px;
		font-weight: bold;
		line-height: 1.2;
	}
}
