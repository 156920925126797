@import '../../../variables';
@import '../../../mixins';

.playback-information {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 100;
	touch-action: none;

	.playback-information-content {
		width: 100%;
		height: 100%;
		@include transition(opacity $fadeInDuration ease-in);
		opacity: 1;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		&.hidden {
			opacity: 0;
			cursor: none;
		}
	}

	.playback-information-background {
		background: url('../../../../public/images/player-controls-bg.png') repeat-x left bottom;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: -1;
	}

	.playback-information-cancel {
		display: flex;
		padding: 10px;

		.playback-information-cancel-button {
			background-image: url('../../../../public/images/icon_left.png');
			background-size: 100%;
			background-repeat: no-repeat;
			width: 50px;
			height: 50px;
			cursor: pointer;
		}
	}

	.playback-information-bar-wrapper {
		min-width: 0;
		flex: 0;
		padding: 50px;
		@include media-breakpoint-down(md) {
			padding: 30px;
		}

		@include media-breakpoint-down(sm) {
			padding: 20px;
		}
	}

	.playback-information-bar {
		min-width: 0;
		flex: 0;
		display: flex;
		align-items: center;
	}

	.playback-information-wrapper {
		position: relative;
		flex: 1;
		display: flex;
		flex-direction: column;
		min-width: 0;

		.playback-information-title-settings-wrapper {
			min-width: 0;
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-flow: row nowrap;
			flex: 1;
		}

		.playback-information-title {
			color: #fff;
			font-size: 52px;
			@include ellipsis();
			@include noselect();

			@include media-breakpoint-down(md) {
				font-size: 42px;
				line-height: 1.2;
			}

			@include media-breakpoint-down(sm) {
				font-size: 32px;
			}
		}
	}

	.playback-information-ad-skip {
		cursor: pointer;
		position: absolute;
		bottom: 40px;
		right: 80px;

		.playback-information-ad-skip-circle-background {
			fill: #2a2a2a;
		}

		.playback-information-ad-skip-circle {
			//transition: .2s stroke-dashoffset;
			transform: rotate(-90deg);
			transform-origin: 50% 50%;
		}

		.playback-information-ad-skip-progress {
			position: absolute;
			top: 20px;
			width: 100%;
			text-align: center;
			color: #fff;
			font-size: 28px;

			svg {
				fill: #fff;
				width: 30px;
				height: 30px;
				position: absolute;
				top: 6px;
				left: 28px;
			}
		}
	}
}

.playback-settings {
	display: flex;
	flex: 0;
	position: relative;

	> * {
		margin-left: 20px;
		cursor: pointer;
	}

	.playback-settings-fullscreen {
		width: 20px;
		height: 20px;
		background: url('../../../../public/images/fullscreen.png') no-repeat;
		background-size: 100% 100%;
	}

	.playback-settings-volume {
		width: 20px;
		height: 20px;

		.playback-settings-volume-button {
			width: 20px;
			height: 20px;
		}

		.playback-settings-volume-slider-container {
			display: none;
			height: 120px;
			width: 20px;
			position: absolute;
			top: 0;
			transform: translateY(-100%);
			padding-top: 10px;
			padding-bottom: 10px;

			.playback-settings-volume-slider {
				width: 100%;
				height: 100%;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
			}

			.volume {
				width: 3px;
				background-color: #e60000;
			}

			.volume-indicator {
				background-color: #fff;
				width: 10px;
				height: 10px;
				min-width: 10px;
				min-height: 10px;
				border-radius: 5px;
				margin-left: -5px;
				margin-right: -5px;
			}

			.volume-remaining {
				width: 3px;
				background-color: #fff;
			}
		}

		&.selected .playback-settings-volume-slider-container {
			display: block;
		}
	}

	.playback-settings-cast {
		width: 20px;
		height: 20px;
		background: url('../../../../public/images/cast.png') no-repeat;
		background-size: 100% 100%;

		&.playback-settings-cast-unavailable {
			display: none;
		}

		&.playback-settings-cast-connecting {
			animation: chromecast-connecting 1s linear infinite 0s;
		}

		&.playback-settings-cast-connected {
			background-image: url('../../../../public/images/cast_connected.png');
		}
	}

	.playback-settings-quality {
		position: relative;
		width: 60px;
		text-align: center;
		@include noselect();

		&.selected {
			.playback-settings-quality-selection {
				display: block;
			}
		}

		.playback-settings-quality-selection {
			display: none;
			width: 60px;
			padding: 10px 5px;
			position: absolute;
			top: 0;
			left: 0;
			transform: translateY(-100%);

			ul {
				list-style: none;
				margin: 0;
				padding: 0;
			}

			li.active {
				font-weight: bold;
			}
		}
	}

	.playback-settings-subtitles {
		position: relative;
		width: 80px;
		text-align: center;

		&.selected {
			.playback-settings-subtitles-selection {
				display: block;
			}
		}

		.playback-settings-subtitles-selection {
			display: none;
			width: 80px;
			padding: 10px 5px;
			position: absolute;
			top: 0;
			left: 0;
			transform: translateY(-100%);

			ul {
				list-style: none;
				margin: 0;
				padding: 0;
			}

			li.active {
				font-weight: bold;
			}
		}
	}

	.playback-settings-audio {
		position: relative;
		width: 80px;
		text-align: center;

		&.selected {
			.playback-settings-audio-selection {
				display: block;
			}
		}

		.playback-settings-audio-selection {
			display: none;
			width: 80px;
			padding: 10px 5px;
			position: absolute;
			top: 0;
			left: 0;
			transform: translateY(-100%);

			ul {
				list-style: none;
				margin: 0;
				padding: 0;
			}

			li.active {
				font-weight: bold;
			}
		}
	}
}

@keyframes chromecast-connecting {
	0% {
		background-image: url('../../../../public/images/cast_connecting_1.png');
	}

	50% {
		background-image: url('../../../../public/images/cast_connecting_2.png');
	}

	100% {
		background-image: url('../../../../public/images/cast_connecting_3.png');
	}
}
