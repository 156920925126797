@import 'variables';
@import 'mixins';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/root';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/tables';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/alert';
@import '~bootstrap/scss/progress';
@import '~bootstrap/scss/media';
@import '~bootstrap/scss/utilities';
@import '~bootstrap/scss/print';

@font-face {
	font-family: 'Vodafone';
	src: url('../public/fonts/VodafoneRg.ttf') format('truetype');
	//url('../static/fonts/vodafonerg-webfont.woff2') format('woff2'),
	//url('../static/fonts/vodafonerg-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Vodafone';
	src: url('../public/fonts/VodafoneRgBd.ttf') format('truetype');
	//url('../static/fonts/vodafonergbd-webfont.woff2') format('woff2'),
	//url('../static/fonts/vodafonergbd-webfont.woff') format('woff');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Vodafone';
	src: url('../public/fonts/VodafoneExB.ttf') format('truetype');
	//url('../static/fonts/vodafoneexb-webfont.woff2') format('woff2'),
	//url('/fonts/vodafoneexb-webfont.woff') format('woff');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Vodafone';
	src: url('../public/fonts/VodafoneLt.ttf') format('truetype');
	//url('../static/fonts/vodafonelt-webfont.woff2') format('woff2'),
	//url('../static/fonts/vodafonelt-webfont.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Albert Sans';
	src: url('../public/fonts/albertSans/AlbertSans-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Albert Sans';
	src: url('../public/fonts/albertSans/AlbertSans-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Albert Sans';
	src: url('../public/fonts/albertSans/AlbertSans-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Albert Sans';
	src: url('../public/fonts/albertSans/AlbertSans-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Albert Sans';
	src: url('../public/fonts/albertSans/AlbertSans-ExtraBold.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Albert Sans';
	src: url('../public/fonts/albertSans/AlbertSans-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

html,
body {
	margin: 0;
	padding: 0;
}

body {
	font-family: 'Vodafone', 'Helvetica Neue', sans-serif;
	color: #eee;
	-webkit-font-smoothing: antialiased;
	font-smoothing: antialiased;
	background-size: 100% 100%;
	background: linear-gradient(217.32deg, #0D2123 3.28%, #000000 67.97%);
	background-color: #000;
	background-attachment: fixed;

	&.disable-overscroll {
		overscroll-behavior-x: none;
	}
}

#__next {
	overflow-x: hidden;
}

.image-component {
	opacity: 0;
	@include transition(opacity 0.4s ease-in-out);

	&.loaded {
		opacity: 1;
	}
}

.btn {
	border-radius: 6px;
}

.btn-primary {
	color: #fff !important;
}

.btn-white {
	color: #000 !important;
}

.main-title {
	margin-bottom: 45px;

	h1 {
		font-size: 45px;
		line-height: 50px;
		height: 48px;
		color: #eee;
		margin: 0;
		padding: 0;
		@include ellipsis();
	}

	.main-title-color {
		height: 40px;
		width: 16px;
		float: left;
		margin-top: 5px;
		margin-right: 21px;
	}
}

.form-large {
	label {
		font-size: 24px;
	}

	.alert {
		text-align: center;
	}

	input[type='text'],
	input[type='email'],
	input[type='password'],
	input[type='phone'],
	input[type='tel'],
	input[type='number'] {
		display: block;
		background: rgba(255, 255, 255, 0.1);
		text-align: center;
		color: #fff;
		font-size: 40px;
		padding: 10px 20px;
		width: 100%;
		height: auto !important;
		border: 2px solid transparent;
		margin-bottom: 20px;
		border-radius: 6px;

		&:focus {
			outline: none;
		}

		&.has-error {
			border-color: #c00;
		}

		&[disabled] {
			color: #999;
		}
	}

	.btn {
		display: block;
		width: 100%;
		font-size: 20px;
		text-transform: uppercase;
		margin: 0;
		padding: 10px 20px;
		margin-bottom: 20px;
		border-radius: 6px;

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.form-actions {
	display: flex;
	justify-content: flex-end;
	padding-bottom: 20px;

	> * {
		margin-left: 10px;
	}

	a {
		color: #fff;
		text-decoration: none;
		@include transition(color 0.2s ease-in);

		@media (hover) {
			color: #fff;

			&:hover {
				color: $primary;
			}
		}
	}
}

button {
	border: none;
	margin: 0;
	padding: 0;
	width: auto;
	overflow: visible;
	background: transparent;
	outline: none;

	/* inherit font & color from ancestor */
	color: inherit;
	font: inherit;
	text-align: inherit;

	/* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
	line-height: normal;

	/* Corrects font smoothing for webkit */
	-webkit-font-smoothing: inherit;
	-moz-osx-font-smoothing: inherit;

	/* Corrects inability to style clickable `input` types in iOS */
	-webkit-appearance: none;

	&::-moz-focus-inner {
		border: 0;
		padding: 0;
	}

	&:focus {
		outline: none;
	}
}

.background {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: top center;
	background-attachment: fixed;
	z-index: -1;
}

.media-component {
	position: relative;
	margin-bottom: $grid-gutter-width;
	cursor: pointer;
	width: 100%;
	height: 0;
	padding-bottom: 56.25%;

	&.hopster {
		padding-bottom: 100%;
	}

	.icon-image {
		position: absolute;
		left: 10px;
		bottom: 10px;
		width: 48px;
		height: 48px;
		background-size: 100% 100%;
		background-repeat: no-repeat;
	}

	.title-container {
		position: absolute;
		left: 74px;
		bottom: 31px;
		max-width: 65%;
	}

	.title {
		color: #eee;
		font-weight: 700;
		font-size: 21px;
		line-height: 27px;
		@include ellipsis();
	}

	.supporting-text {
		position: absolute;
		color: #a197a1;
		font-weight: 700;
		left: 74px;
		font-size: 17px;
		max-width: 65%;
		line-height: 24px;
		bottom: 10px;
		@include ellipsis();
	}

	&.without-supporting-text {
		.title-container {
			bottom: 19px;
		}

		.supporting-text {
			display: none;
		}
	}

	&.template-overlay {
		.title-container,
		.supporting-text {
			left: 16px;
		}
	}

	.supporting-icon {
		height: 14px;
		width: 14px;
		background-size: 100% 100%;
		background-repeat: no-repeat;
		margin-top: 7px;
		margin-right: 12px;
		float: left;

		&.supporting-icon-stack {
			background-image: url('../public/images/stack@1.5x.png');
		}
	}

	.progress-container {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;

		.progress {
			height: 3px;
			width: 0;
			background-color: #a197a1;
		}
	}

	@media (hover) {
		&.hopster {
			.background-image {
				@include transition(transform 0.1s ease-out);
			}

			&:hover {
				.background-image {
					@include transform(scale(1.2));
				}
			}
		}
	}
}

.series-scene {
	min-height: 100vh;
	display: flex;

	@include media-breakpoint-down(sm) {
		display: block;
	}

	.seasons {
		list-style: none;
		margin: 0 0 20px;
		padding: 0;
		overflow: hidden;

		.season {
			margin-right: 30px;
			font-size: 24px;
			margin-bottom: 10px;
			font-weight: normal;

			@include media-breakpoint-up(md) {
				float: left;
				margin-bottom: 0;
			}

			button {
				@include button-reset();
				display: inline-block;
				color: #666;
				text-decoration: none;
				padding-bottom: 3px;
				border-bottom: 1px solid transparent;

				@media (hover) {
					&:hover {
						color: #eee;
						border-bottom-color: #b90000;
					}
				}
			}

			&.selected {
				button {
					color: #eee;
					border-bottom-color: #666;
				}
			}
		}
	}

	.carousel-wrapper {
		position: relative;
		margin-bottom: 40px;

		.episodes-actions {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			margin-bottom: 10px;
			pointer-events: none;
			display: none;

			.previous,
			.next {
				position: absolute;
				width: 40px;
				height: 100%;
				cursor: pointer;
				background-color: #333;
				background-repeat: no-repeat;
				background-position: center;
				background-size: 14px 28px;
				z-index: 1;
				opacity: 0;

				&.visible {
					opacity: 1;
					pointer-events: auto;
				}

				@media (hover) {
					@include transition(opacity 0.2s ease-in);
				}
			}

			.previous {
				background-image: url('../public/images/chevron-left.png');
				left: 0;
			}

			.next {
				background-image: url('../public/images/chevron-right.png');
				right: 0;
			}
		}

		@media (hover) {
			.episodes-actions {
				display: block;
				opacity: 0.5;
				@include transition(opacity 0.2s ease-in);
			}

			&:hover {
				.episodes-actions {
					opacity: 0.85;
				}
			}
		}
	}
}
