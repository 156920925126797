@import '../../../../variables';
@import '../../../../mixins';

.playback-information-progress-container {
	width: 100%;
	display: none;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	&.visible {
		display: flex;
	}

	.playback-information-progressed-time {
		color: #fff;
		font-size: 28px;
		@include noselect();

		@include media-breakpoint-down(md) {
			font-size: 24px;
		}

		@include media-breakpoint-down(sm) {
			font-size: 18px;
		}
	}

	.playback-information-duration {
		color: #fff;
		font-size: 28px;
		@include noselect();

		&.live {
			cursor: pointer;
		}

		@include media-breakpoint-down(md) {
			font-size: 24px;
		}

		@include media-breakpoint-down(sm) {
			font-size: 18px;
		}
	}
}
